<template>
  <div>
    <canvas ref="chart" />
  </div>
</template>

<script>
import Chart from 'chart.js/auto';

export default {
  props: ['data', 'options'],
  data() {
    return {
      chart: null,
    };
  },
  watch: {
    data: {
      deep: true,
      handler(newData) {
        if (this.chart) {
          this.chart.data = newData;
          this.chart.update();
        }
      },
    },
  },
  mounted() {
    this.chart = new Chart(this.$refs.chart.getContext('2d'), {
      type: 'bar',
      data: this.data,
      options: this.options,
    });
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  },
};
</script>