<template>
  <div id="app" class="container-fluid">
    <div class="dashboard">
      <!-- <div class="content tickets">
        <h3 class="title">Hi</h3>
      </div> -->
      <div class="content chart-area">
        <div v-if="displayState === 'stats'" class="stats-box">
          <h3 class="title">Calls Made</h3>
          <div class="chart">
            <BarChart :data="barChartData" :options="chartOptions" />
          </div>
        </div>
      </div>
      <div class="content table2">
    <h3 class="title">Information</h3>
    <div class="ticket-stats">
  <div 
    class="ticket-stats-item badge" 
    :class="[member.displayName === 'Lucy Brannan' ? 'badge-secondary' : 'badge-info', getClassByCallTime(member.latestCallStartTime)]" 
    v-for="member in filteredStaff" 
    :key="member.displayName">
    {{ callDurations[member.displayName] }} 
    <p>{{ member.displayName.split(' ')[0] }} ({{ member.outboundAnsweredCount }})</p>
  </div>
</div>
  </div>
    </div>
  </div>
</template>

<script>
import BarChart from './BarChart.vue';

export default {
  components: {
    BarChart,
  },
  data() {
    return {
      staffCallStats: [],
      chartOptions: {
        animation: false,
        
      },
      callDurations: {
      Lucy: "0:00",
      Sam: "0:00",
      },
      displayState: 'stats',
    };
  },
  computed: {
    barChartData() {
      const relevantStaff = this.staffCallStats.filter(member =>
        member.displayName === "Lucy Brannan" || member.displayName === "Sam Maley" || member.displayName === "Jonny Hood"
      );
      
      return {
        labels: relevantStaff.map(member => member.displayName),
        datasets: [
          {
            label: 'Outgoing Calls Today',
            backgroundColor: ['#FF6384', '#36A2EB'],
            data: relevantStaff.map(member => parseInt(member.outboundAnsweredCount) || 0)
          }
        ]
      };
    },
    filteredStaff() {
    return this.staffCallStats.filter(member =>
      member.displayName === "Lucy Brannan" || member.displayName === "Sam Maley" || member.displayName === "Jonny Hood"
    );
  },
  },
  methods: {
    async fetchCallStats() {
      try {
        const response = await fetch('https://documaker.coffeecup.solutions/api/VoIP');
        if (!response.ok) {
          throw new Error(`HTTP error: ${response.status}`);
        }
        const data = await response.json();
        this.staffCallStats = data;
        this.processDurations();
      } catch (error) {
        console.error('Fetching call stats failed:', error);
      }
    },

    getClassByCallTime(latestCallStartTime) {
    if (!latestCallStartTime) {
      return 'red';
    }
    
    const latestCallTime = new Date(latestCallStartTime);
    const currentTime = new Date();
    const timeDifference = (currentTime - latestCallTime) / 60000; // Difference in minutes

        if (timeDifference > 20) {
          return 'red';
        } else if (timeDifference >= 8 && timeDifference <= 20) {
          return 'orange';
        } else {
          return 'green';
        }
      },
   

      formatDuration(isoDuration) {
    if (!isoDuration) return "0:00";

    let totalMinutes = 0;

    const hoursMatch = isoDuration.match(/(\d+)H/);
    if (hoursMatch) {
      totalMinutes += parseInt(hoursMatch[1], 10) * 60;
    }

    const minutesMatch = isoDuration.match(/(\d+)M/);
    if (minutesMatch) {
      totalMinutes += parseInt(minutesMatch[1], 10);
    }



    const hours = Math.floor(totalMinutes / 60).toString().padStart(2, '0');
    const minutes = (totalMinutes % 60).toString().padStart(2, '0');

    return `${hours}:${minutes}`;
  },
  processDurations() {
    this.filteredStaff.forEach(member => {
      const displayName = member.displayName;
      this.callDurations[displayName] = this.formatDuration(member.outboundAnsweredTalkingDur);
    });
  },

    
  },
  async created() {
    await this.fetchCallStats();
   
    this.interval = setInterval(() => {
      this.fetchCallStats();
    }, 30000); // Refresh every 60 seconds
  },
  beforeDestroy() {
    // Clear the interval when the component is destroyed
    clearInterval(this.interval);
  },
};
</script>
<style scoped>
html,
body {
  height: 100%;
  background-color: #f8f9fa;
}

.content.chart-area {
  height: auto;
  overflow: visible;
  padding-bottom: 0;
}

.stats-box,
.ticket-info-box,
.phone-stats-box {
  height: auto;
  overflow: visible;
  padding-bottom: 0;
}

.chart {
  height: calc(100% - 2rem); /* Subtract the height of the title */
}

.title {
  text-align: center;
  margin-bottom: 1rem;
  font-family: "Quicksand", sans-serif;
  font-weight: bold;
}

.content.chart-area .title {
  margin-bottom: 0;
}

.dashboard {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;
  grid-gap: 1rem;
  height: calc(100vh - 2rem);
  margin-top: 1rem;
}

.content.tickets {
  grid-row: 1 / span 2;
  height: 100%;
}

.content::-webkit-scrollbar {
  display: none; /* Hide scrollbar */
}

.content {
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  background-color: #ffffff;
  padding: 1rem;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
  height: calc(100vh - 2rem); /* Ensure fixed height */
  overflow: auto; /* Enable scrolling content */
  position: relative;
}

@keyframes slow-scroll {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-50%); /* Customize this value based on your needs */
  }
}

.autoscroll {
  animation: slow-scroll 60s linear infinite; /* Add slow automatic scroll animation */
}

.graph {
  width: 100%;
  height: 22rem;
  background: linear-gradient(to right, #7b4397, #dc2430);
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 1.5rem;
}

.bg-danger {
  background-color: #f86c6b;
}

.alert.custom-alert {
  display: flex;
  align-items: center;
  background-color: #f86c6b;
  border-radius: 0.25rem;
  padding: 0.75rem 1rem;
  color: #ffffff;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.alert.custom-alert:last-child {
  margin-bottom: 0;
}

.alert-icon {
  font-size: 1.25rem;
  margin-right: 0.5rem;
}

.ticket-stats {
  display: flex;
  flex-direction: column; 
  align-items: center; 
  width: 100%; 
}

.ticket-stats-item {
  text-align: center;
  font-size: 6rem;
  font-family: "Quicksand", sans-serif;
  font-weight: 700;
  width: 100%; 
  padding: 2rem 0; 
  box-sizing: border-box;
  margin-bottom: 40px;
}

.staff-call-count {
  font-weight: bold;
  margin-bottom: 0.5rem; 
}
.ticket-stats-item p {

  font-size: 4rem;
  font-family: "Quicksand", sans-serif;
  font-weight: normal;
}

.red {
  background-color: #6e6e6e; 
}

.orange {
  background-color: #6e6e6e; /* Change the hex code to the shade of orange you prefer */
}

.green {
  background-color: #6e6e6e; /* Change the hex code to the shade of green you prefer */
}

</style>
